import { FlexModuleModelStore } from "src/stores/FlexModuleModelStore";
import { StorefrontWizardRegionTAAPFlexModelResult } from "./typings";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";

/**
 * @param flexManagerChildren list of Wizard LOBs that are set up in Flex Manager
 * @param userLobList string array of lob keys that should be present for the user that is logged in.
 * All Keys available for userLobList = ["hotel", "flight", "car", "gt", "package", "lx", "externalLinkTab"]
 * @return the filtered list of children with the updated lob configuration based on the userLobList
 */
export const filterLobsFromOverrideList = (
  templateComponents: ExtendedTemplateComponent[],
  userLobList: string[],
  flexModuleModelStore: FlexModuleModelStore
): ExtendedTemplateComponent[] => {
  const isSingleLob = userLobList?.length === 1;
  return templateComponents.filter((templateComponent: ExtendedTemplateComponent) => {
    const id = templateComponent.metadata.id;
    let model = flexModuleModelStore.getModel(
      templateComponent.metadata.id
    ) as StorefrontWizardRegionTAAPFlexModelResult | null;
    /* istanbul ignore next */
    if (!model) {
      return null;
    }

    if (id.indexOf("hotel") !== -1 && userLobList.includes("hotel")) {
      model.enableAddCar = userLobList.includes("car");
      model.enableAddFlight = userLobList.includes("flight");
      model.hideLobTitle = !isSingleLob;

      return templateComponent;
    }

    if (id.indexOf("car") !== -1 && userLobList.includes("car")) {
      model.enableAddHotel = userLobList.includes("hotel");
      model.enableAddFlight = userLobList.includes("flight");
      model.hideLobTitle = !isSingleLob;

      return templateComponent;
    }

    if (id.indexOf("package") !== -1 && userLobList.includes("package")) {
      model.hideLobTitle = !isSingleLob;
      model = { ...model, packageTypes: "" };

      if (userLobList.includes("car") && userLobList.includes("flights")) {
        model.packageTypes = "flightscars";
      } else if (userLobList.includes("car") && userLobList.includes("hotel")) {
        model.packageTypes = "hotelscars";
      } else if (userLobList.includes("flights") && userLobList.includes("hotel")) {
        model.packageTypes = "flightshotels";
      } else if (userLobList.includes("flights") && userLobList.includes("hotel") && userLobList.includes("car")) {
        model.packageTypes = "flightshotelscars";
      }

      if (model.packageTypes) {
        // if there is no min 2 lobs needed for a package the package tab will not display,
        // so we only return if packageTypes is not empty
        return templateComponent;
      }

      return null;
    }

    if (id.indexOf("flight") !== -1 && userLobList.includes("flight")) {
      model.enableAddCar = userLobList.includes("car");
      model.enableAddHotel = userLobList.includes("hotel");
      model.hideLobTitle = !isSingleLob;

      return templateComponent;
    }

    if (id.indexOf("gt") !== -1 && userLobList.includes("gt")) {
      model.hideLobTitle = !isSingleLob;

      return templateComponent;
    }

    if (id.indexOf("lx") !== -1 && userLobList.includes("lx")) {
      model.hideLobTitle = !isSingleLob;

      return templateComponent;
    }

    if (id.indexOf("externalLinkTab") !== -1 && userLobList.includes("externalLinkTab")) {
      model.hideLobTitle = !isSingleLob;

      return templateComponent;
    }

    return null;
  });
};
