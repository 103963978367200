import { LobNameMapRef } from "./typings";

export const LOB_NAME_MAP_REF: LobNameMapRef = {
  "wizard-hotel-pwa-v2": "_Hotels",
  "wizard-flight-pwa": "_Flights",
  "wizard-package-pwa": "_Packages",
  "wizard-car-pwa": "_Cars",
  "wizard-gt-pwa": "_AirportTransfers",
  "wizard-lx-pwa": "_Lx",
  "wizard-cruise-pwa": "_Cruise",
  "wizard-externalLinkTab-pwa": "_ExternalLinkTab",
};
